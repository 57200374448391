exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ayuda-js": () => import("./../../../src/pages/ayuda.js" /* webpackChunkName: "component---src-pages-ayuda-js" */),
  "component---src-pages-comunidad-volter-js": () => import("./../../../src/pages/comunidad-volter.js" /* webpackChunkName: "component---src-pages-comunidad-volter-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-flota-voltio-js": () => import("./../../../src/pages/flota-voltio.js" /* webpackChunkName: "component---src-pages-flota-voltio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movilidad-sostenible-js": () => import("./../../../src/pages/movilidad-sostenible.js" /* webpackChunkName: "component---src-pages-movilidad-sostenible-js" */),
  "component---src-pages-mutualistas-js": () => import("./../../../src/pages/mutualistas.js" /* webpackChunkName: "component---src-pages-mutualistas-js" */),
  "component---src-pages-sobre-voltio-js": () => import("./../../../src/pages/sobre-voltio.js" /* webpackChunkName: "component---src-pages-sobre-voltio-js" */),
  "component---src-pages-tarifas-voltio-js": () => import("./../../../src/pages/tarifas-voltio.js" /* webpackChunkName: "component---src-pages-tarifas-voltio-js" */),
  "component---src-pages-zona-voltio-js": () => import("./../../../src/pages/zona-voltio.js" /* webpackChunkName: "component---src-pages-zona-voltio-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-press-post-js": () => import("./../../../src/templates/press-post.js" /* webpackChunkName: "component---src-templates-press-post-js" */),
  "component---src-templates-press-template-js": () => import("./../../../src/templates/press-template.js" /* webpackChunkName: "component---src-templates-press-template-js" */)
}

